<template>
  <v-card
    dark
    style="width: 100%"
    class="blue d-flex px-5"
  >
    <v-icon size="45" class="mx-4">mdi-account</v-icon>
    <div class="d-flex flex-column justify-center">
      <v-card-title class="headline">
        {{ count }}
      </v-card-title>

      <v-card-subtitle>{{
          $vuetify.lang.t('$vuetify.general.users.list.ui.widget.number-of-users.label')
                       }}
      </v-card-subtitle>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'WidgetNumberOfClients',
  data: () => ({
    count: '-'
  }),
  methods: {
    async fetch () {
      try {
        this.count = (await this.axios.get('/general/users/numberUsers', {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data.count
      } catch (e) {
        this.Sentry.captureException(e)
      }
    },
    async reload () {
      this.count = '-'
      await this.fetch()
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>

<style lang="scss" scoped>
</style>
